import React from "react";
import { Col, Row } from "react-bootstrap";


function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        Car
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        Trains
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        Flights
      </Col> 
      <Col xs={4} md={2} className="tech-icons">
        Hotels
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        Resort
      </Col>
      
    </Row>
  );
}

export default Toolstack;
