import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";
import Slider from "../Slider/Sliderabt";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Toolstack from "./Toolstack";
import about01 from "../../Assets/about01.jpg";
import about02 from "../../Assets/about02.jpg"; 


function About() {
  return (
    <Container fluid className="about-section">  
      {/* <Particle /> */}
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}> 
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px", 
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know About <strong className="purple">US</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={about01} alt="about" className="img-fluid" />
            <br /> ---
            <img src={about02} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 className="project-heading">
          Past <strong className="purple">Trips </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Other</strong> Services
        </h1>
        <Toolstack />
        <h1 className="project-heading">
          <strong className="purple">Glimpses of</strong> Trips
        </h1>
        <Slider />

      </Container>
    </Container>
  );
}

export default About;
