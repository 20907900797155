import React from 'react'
import MenuCard from "./MenuCard";
import m401 from "../../Assets/m401.jpg";
import m402 from "../../Assets/m402.jpg";
import m403 from "../../Assets/m403.jpg";
import m404 from "../../Assets/m404.jpg";
import m405 from "../../Assets/m405.jpg";
import {  Row, Col } from "react-bootstrap";

const MenuOne = ({menuData}) => {
    const submitForm = () => {  
        window.open(
          `mailto:sharayuholidays.mumbai@gmail.com?subject=${encodeURIComponent(
            "Package Information"
          )}&body=${encodeURIComponent("name")} (${encodeURIComponent( 
            "email"
          )}): ${encodeURIComponent("message")}`
        );
      }; 

    return (
        <>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <MenuCard
              imgPath={m401}
              isBlog={false}
              title="Ahmedabad"
              description="Ahmedabad - Dasada(Little Rann of Kutch) - Patan"
              onClick={submitForm}
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <MenuCard
              imgPath={m402}
              isBlog={false}
              title="Runn of Kutch"
              description="Rann of Kutch - Bhuj - Nirona Village - Ajrakpur - Mandvi"
              link=""
              onClick={submitForm}

            />
          </Col>

          <Col md={4} className="project-card">
            <MenuCard
              imgPath={m403}
              isBlog={false}
              title="Rajkot"
              description="Rajkot Junagadh - Gir - Somnath - Diu - Dwarka
              onClick={submitForm}
"
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <MenuCard
              imgPath={m404}
              isBlog={false}
              title="Jaipur"
              description=" Jaipur-Jodhpur-Jaisalmer-Bikaner - Ajmer
              onClick={submitForm}
"
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <MenuCard
            
              imgPath={m405}
              isBlog={false}
              title="Udaipur"
              description="Udaipur-Mount Abu-Chittorgarh - Kumbhalgarh
"
              link=""
            />
          </Col>

          
        </Row>
        </>
    );
};

export default MenuOne
