import React from "react";
import { Col, Row } from "react-bootstrap";


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
      Ladakh
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        Rajasthan
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      kashmir
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      Himachal
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      Uttarakhand
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      Gujarat
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        Cochin
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        Kedarnath
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        SouthInd
      </Col>
      
    </Row>
  );
}

export default Techstack;
