import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
// import Particle from "../Particle";
import camps01 from "../../Assets/camps01.jpg";
import camps02 from "../../Assets/camps02.jpg";
import camps03 from "../../Assets/camps03.jpeg";
import camps04 from "../../Assets/camps04.jpg";
import camps05 from "../../Assets/camps05.jpg";
import camps06 from "../../Assets/camps06.jpg";
import camps07 from "../../Assets/camps07.jpg";
import camps08 from "../../Assets/camps08.jpg";
import {
  AiFillPhone,
  
} from "react-icons/ai";

function Projects() {
  return ( 
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
         Camps and Industrial Visits <strong className="purple"> </strong>
        </h1>
        <p style={{ color: "white" }}>
          Say Goodbye To Stress !
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={camps01}
              isBlog={false}
              title="Silvassa"
              description="Prakruti Parichay Kendra, Butterfly garden, Adivasi Museum, Satmaliya Deer Park, Linn Safari,
Dudhani lake, Swaminarayan Temple, Nakshatra Garden, Dadra gardes Factory Industrial visit and Adventure activities"
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={camps02}
              isBlog={false}
              title="Lonavala"
              description="Karla caves, N.A.B. Tiger Leap Point, Bhashi Dam, Wax Museum,

Rajmachi Point, Zenith Waterfall, Lohagad fort, Industrial visit
"
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={camps03}
              isBlog={false}
              title="Pune"
              description="Katraj Snake Park, Katraj Zon, Aga Khan Palace, Shaniwar Wads, Sinhagad fort,

Balaji Mandir, NDA, Factory/ Industrial visit
"
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={camps04}
              isBlog={false}
              title="Nashik"
              description="
Phalke Memorial, Stupa, Pandav cave, Sita Gufa, Ram kund, Sita kund. Pagoda, Coin Museum & Factory/Industrial visit and Adventure activities
"
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
            
              imgPath={camps05}
              isBlog={false}
              title="Daman"
              description="Devka Beach and Fort, Bom Jesus Church, Jetty Garden, Nani Daman Fort,

Factory/ Industrialvisit and Adventure activities
"
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={camps06}
              isBlog={false}
              title="Mahabaleshwar"
              description="Table Land, Mapro Foods Centre, Venna Lake,

Old Mahabaleshwar temple, Panch Dhara,

Krishna temple, Pratapgad Fort, Parsi Point,

Sunset Point, Factory/ Industrial visits
"
              link=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={camps07}
              isBlog={false}
              title="IgatPuri"
              description="Basti Dam, Phalke Memorial, Stupa Panday cave, Sita Gefa, Ram kund, Sita kund, Pagoda & Meditation center, Factory/Industrial visit and Adventure activities
"
              link=""
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={camps08}
              isBlog={false}
              title="Karnala"
              description="NO Go Green Horticulture Centre,

Rural Industry, Karnala bird sanctuary. Local and School Village, Trek
"
              link=""
            />
          </Col>
          <Col md={12} className="home-about-social">
            <h1>Call Us for More Trips!</h1>
            <p style={{ color: "white" }}>
          like Dapoli, Karjat, Alibaug, saputara, Malvan, Kolhapur Etc.
        </p>
            <ul className="home-about-social-links">
              
              <li className="social-icons">
                <a
                  href="tel://+919769153597"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillPhone />
                </a>
              </li>
              
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
