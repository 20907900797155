import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import home2 from "../../Assets/home2.jpg";
import Slider from "../Slider/Sliderabt";


import {
  AiFillPhone,
  AiOutlineWhatsApp,
  AiFillFacebook,
  AiFillInstagram,
  AiFillMail,
} from "react-icons/ai";

function Home2() {
  const submitForm = () => {
    window.open(
      `mailto:sharayuholidays.mumbai@gmail.com?subject=${encodeURIComponent(
        "Information"
      )}&body=${encodeURIComponent("name")} (${encodeURIComponent(
        "email"
      )}): ${encodeURIComponent("message")}`
    );
  };
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Know our <span className="purple"> Work </span> Ethics
            </h1>
            <p className="home-about-body">
            ARCHITECTURE TOURS, EDUCATIONAL/PROJECT TOURS INDUSTRIAL VISIT TOURS ADVENTURE TOURS DOMESTIC & INTERNATIONAL TOURS ONE DAY PICNICS SCOUTS & GUIDES CAMPS, CORPORATE TOURS, SEMINAR & CONFERENCES / EVENTS, AIR/ TRAIN ,HOTEL/ CAR & BUS BOOKINGS.
              <br />We are Based in
              <i>
                <b className="purple">{" "}Mumbai </b>
              </i>
              <br />
              <br />
             Our Primary Goal is &nbsp;
              <i>
               {" "}
                <b className="purple">
                 Smile at Your Faces{" "} 
                </b>
                
              </i>
              🙂
              <br />
              <br />
              <b className="purple">"Strive to build things that make a difference!"

--Sharayu</b> 
              <i>
                <b className="purple">
                  {" "}
                  
                </b>
              </i>
              &nbsp; 
              <i>
              </i>
            </p>
          </Col>
          
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={home2} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
        
        
          <Col md={12} className="home-about-social">
          <br />
              <br />
          <h1 className="project-heading">
          <strong className="purple">Glimpses of</strong> Trips
        </h1>
        <Slider />
        <br />
              <br />
            <h1>FIND US ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with Us
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/Sharayu-Holidays-104726145386068"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://wa.me/message/RCPZXKH4YXFYO1"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineWhatsApp />
                </a>
              </li>
              
              <li className="social-icons">
                <a
                  href=" "
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  onClick={submitForm}
                >
                  <AiFillMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://instagram.com/sharayuholidays?utm_medium=copy_link"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
          <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      
      
    </Row>
          <Col md={12} className="home-about-social">
            <h1>Call Us!</h1>
            
            <ul className="home-about-social-links">
              
              <li className="social-icons">
                <a
                  href="tel://+919769153597"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillPhone />
                  
                </a>
              </li>
              
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
