import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        {/* <Particle /> */}
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi Explorer! <span className="wave" role="img" aria-labelledby="wave">👋🏻</span>
              </h1>

              <h4 className="heading-name">
              Welcome to Sharayu Holidays, 
                Travel
                <strong className="main-name"> The World!</strong>
              </h4>

              <div style={{ padding: 50, textAlign: "left",paddingBottom:250 }}>
              <strong className="main-name"> <Type /></strong>
                
              </div> 
            </Col>

            {/* <Col md={5} style={{ padding: 20, marginBottom:50, height:40 }}>
              <img src={homeLogo} alt="home pic" className="img-fluid" />
            </Col> */}
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
