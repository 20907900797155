import React from "react";
import MenuCard from "./MenuCard";
import m201 from "../../Assets/m201.jpg";
import m202 from "../../Assets/m202.jpg";
import m203 from "../../Assets/m203.jpg";
import m204 from "../../Assets/m204.jpg";
import m205 from "../../Assets/m205.jpg";

import { Row, Col } from "react-bootstrap";

const MenuTwo = ({ menuData }) => {
  const submitForm = () => {
    window.open(
      `mailto:sharayuholidays.mumbai@gmail.com?subject=${encodeURIComponent(
        "Package Information"
      )}&body=${encodeURIComponent("name")} (${encodeURIComponent(
        "email"
      )}): ${encodeURIComponent("message")}`
    );
  };

  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m201}
            isBlog={false}
            title="West Bengal"
            description="Kolkata - Darjeeling - Gangtok - Pelling - Sundarban - Shanti Niketan

"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m202}
            isBlog={false}
            title="Odisha"
            description="Bhubaneshwar-Konark - Puri - Chilka
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m203}
            isBlog={false}
            title="Guwahati"
            description="Phuntsholing - Thimphu - Paro - Punakha (Bhutan)
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m204}
            isBlog={false}
            title="Kathmandu"
            description="Bangalore - Mysore - Ooty/Coorg - Shravanavelagola
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m205}
            isBlog={false}
            title="Bhutan "
            description=" Kathmandu - Pokhara Bhaktapur - Chitwan (Nepal) 
"
            link=""
          />
        </Col>
      </Row>
    </>
  );
};

export default MenuTwo;
