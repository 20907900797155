import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillFacebook,
  AiFillPhone,
  AiOutlineWhatsApp,
  AiFillInstagram,
  AiFillMail,
} from "react-icons/ai"; 
 
function Footer() {
  const submitForm = () => {
    window.open(
      `mailto:sharayuholidays.mumbai@gmail.com?subject=${encodeURIComponent(
        "Information"
      )}&body=${encodeURIComponent("name")} (${encodeURIComponent(
        "email"
      )}): ${encodeURIComponent("message")}`
    );
  };
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Designed and Developed by {"  "}  <a className="purple"  href="https://ritish.live/" target="_blank" rel="noopener noreferrer">RMX</a> </h3>
          
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} </h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://www.facebook.com/Sharayu-Holidays-104726145386068"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillFacebook />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://wa.me/message/RCPZXKH4YXFYO1"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiOutlineWhatsApp />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="tel://+919769153597"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillPhone />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="sharayuholidays.mumbai@gmail.com"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
                onClick={submitForm}
              >
                <AiFillMail />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://instagram.com/sharayuholidays?utm_medium=copy_link"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li> 
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
