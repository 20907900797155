import React from "react";
import MenuCard from "./MenuCard";
import m101 from "../../Assets/m101.jpeg";
import m102 from "../../Assets/m102.jpeg";
import m103 from "../../Assets/m103.jpeg";
import m104 from "../../Assets/m104.jpeg";
import m105 from "../../Assets/m105.jpg";
 
import { Row, Col } from "react-bootstrap"; 

const MenuOne = ({ menuData }) => {
  const submitForm = () => {
    window.open(
      `mailto:sharayuholidays.mumbai@gmail.com?subject=${encodeURIComponent(
        "Package Information"
      )}&body=${encodeURIComponent("name")} (${encodeURIComponent(
        "email"
      )}): ${encodeURIComponent("message")}`
    );
  };

  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m101}
            isBlog={false}
            title="Uttrakhand"
            description="(Jim Corbett - Nainital - Mussoorie - Haridwar - Rishikesh)
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m102}
            isBlog={false}
            title="Kedarnath"
            description="(Haridwar - Kedarnath - Rishikesh)
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m103}
            isBlog={false}
            title="Do Dham"
            description="(Haridwar - Kedarnath - Badrinath - Rishikesh)
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m104}
            isBlog={false}
            title="Char Dham"
            description="(Haridwar - Kedarnath - Badrinath - Gangotri - Yamunotri - Rishikesh)
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m105}
            isBlog={false}
            title="Jhansi"
            description="Gwalior -Orchha - Jhansi - Shivpuri Jammu-Srinagar - Gulmarg - Pahalgam - Sonmarg
"
            link=""
          />
        </Col>
      </Row>
    </>
  );
};

export default MenuOne;
