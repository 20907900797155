import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";
import {
  AiFillCalendar,
  AiFillAccountBook
} from "react-icons/ai";
import { BsFillStarFill } from "react-icons/bs";

function ProjectCards(props) {
  const submitForm = () => {  
    window.open(
      `mailto:sharayuholidays.mumbai@gmail.com?subject=${encodeURIComponent(
        "Package Information"
      )}&body=${encodeURIComponent("name")} (${encodeURIComponent( 
        "email"
      )}): ${encodeURIComponent("message")}`
    );
  }; 
  return (
    <Card className="project-card-view">
      <Card.Img variant="top"  src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title} </Card.Title>
      <AiFillCalendar /><span  bg="primary"> Flexible  &nbsp; </span>
      <AiFillAccountBook /><span bg="primary"> 1N/2D &nbsp;&nbsp;</span>
      {/* <FaPlaneArrival /><span bg="primary"> Flight &nbsp;&nbsp;</span> */}
      <BsFillStarFill /><BsFillStarFill /><BsFillStarFill /><BsFillStarFill /><span bg="primary"> Hotel &nbsp;&nbsp;</span>

      {/* <BsFillPeopleFill /> <span  bg="primary"> 30&nbsp;&nbsp;</span> */}
      <br />&nbsp;
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description} 
        </Card.Text>
        <Button variant="primary" onClick={submitForm} href={props.link} target="_blank">
          <BiLinkExternal /> &nbsp;
          {props.isBlog ? "View Blog" : "Intrested"}
        </Button>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
