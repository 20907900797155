import React from "react";
import MenuCard from "./MenuCard";
import m301 from "../../Assets/m301.jpg";
import m302 from "../../Assets/m302.jpg";
import m303 from "../../Assets/m303.jpg";
import m304 from "../../Assets/m304.jpg";
import m305 from "../../Assets/m305.jpg";
import { Row, Col } from "react-bootstrap";

const MenuTwo = ({ menuData }) => {
  const submitForm = () => {
    window.open(
      `mailto:sharayuholidays.mumbai@gmail.com?subject=${encodeURIComponent(
        "Package Information"
      )}&body=${encodeURIComponent("name")} (${encodeURIComponent(
        "email"
      )}): ${encodeURIComponent("message")}`
    );
  };

  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m301}
            isBlog={false}
            title="Cochin"
            description="Cochin - Munnar - Thekkady - Alleppey - Trivandrum - Guruvayur
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">  
          <MenuCard
            imgPath={m302}
            isBlog={false}
            title="Kanyakumari"
            description="Kanyakumari - Madurai - Kodaikanal - Rameshwaram Thanjavur
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m303}
            isBlog={false}
            title="Kerela"
            description="Chennai- Pondicherry - Mahabalipuram - Kanchipuram
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m304}
            isBlog={false}
            title="Bangalore"
            description="Bangalore - Mysore - Ooty/Coorg - Shravanavelagola
"
            onClick={submitForm}
            link=""
          />
        </Col>

        <Col md={4} className="project-card">
          <MenuCard
            imgPath={m305}
            isBlog={false}
            title="Hampi"
            description="Hubli- Hampi - Badami - Hospet - Pattadakal - Aihole
"
            link=""
          />
        </Col>
      </Row>
    </>
  );
};

export default MenuTwo;
