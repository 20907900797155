import { Container, Row ,Col} from "react-bootstrap";
// import Particle from "../Particle";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {Carousel} from "react-bootstrap"; 
import g01 from "../../Assets/g01.jpg";
import g02 from "../../Assets/g02.jpg";
import g03 from "../../Assets/g03.jpg";
import g04 from "../../Assets/g04.jpg";
import g05 from "../../Assets/g05.jpg";
import g06 from "../../Assets/g06.jpg";
import g07 from "../../Assets/g07.jpg";
import g08 from "../../Assets/g08.jpg";
import g09 from "../../Assets/g09.jpg";
import g10 from "../../Assets/g10.jpg";

import {
  AiFillPhone,

} from "react-icons/ai";
function ResumeNew() {
  
  return (
    <div>
      <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
          Our Trips <strong className="purple"> Gallery! </strong>
        </h1>
        <br />
        <h1 className="project-heading">
          Dubai <strong className="purple">  </strong>
        </h1>
        
        <Row style={{ justifyContent: "center", paddingBottom: "10px" ,marginBottom:"10px"}}>
        <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g01}
      alt="First slide"
    />
    <Carousel.Caption>
      {/* <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g02}
      alt="Second slide"
    />

    <Carousel.Caption>
      {/* <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g03}
      alt="Third slide"
    />

    <Carousel.Caption>
      {/* <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g04}
      alt="Third slide"
    />

    <Carousel.Caption>
      {/* <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
 </Carousel>
        </Row>
        <h1 className="project-heading">
          Delhi <strong className="purple">  </strong>
        </h1>
        
        <Row style={{ justifyContent: "center", paddingBottom: "10px" ,marginBottom:"10px"}}>
        <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g05}
      alt="First slide"
    />
    <Carousel.Caption>
      {/* <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g06}
      alt="Second slide"
    />

    <Carousel.Caption>
      {/* <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g07}
      alt="Third slide"
    />

    <Carousel.Caption>
      {/* <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
 </Carousel>
        </Row>
        <h1 className="project-heading">
          Mumbai <strong className="purple">  </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g08}
      alt="First slide"
    />
    <Carousel.Caption>
      {/* <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g09}
      alt="Second slide"
    />

    <Carousel.Caption>
      {/* <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={g10}
      alt="Third slide"
    />

    <Carousel.Caption>
      {/* <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
        </Row>
      </Container>
    </Container>
    <Col md={12} className="home-about-social">
            <h1>Call Us!</h1>
            
            <ul className="home-about-social-links">
              
              <li className="social-icons">
                <a
                  href="tel://+919769153597"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillPhone />
                </a>
              </li>
              
            </ul>
          </Col>
    </div>
  );
}

export default ResumeNew;
