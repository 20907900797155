import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body> 
        <blockquote className="blockquote mb-0"> 
          <p style={{ textAlign: "justify" }}>
          Specialize in:
Field trips, Educational trips, Industrial visits, Hiking and Trekking, One day Picnics, Scout & guide / Educational / NCC / Leisure Camps, Domestic & International Tours.  Our solution allows you to get the most out of your trips within your stipulated budgets. <span className="purple"> </span>
             <span className="purple"></span>
            <br /><br /> We always remain mindful of the requirements of the people; and therefore, we have a team of professionals and highly-qualified individuals who share the same passion as us. We are a small team who are constantly motivated to fulfil the travel dreams of everyone. Unwavering dedication of every member of the team allowed us to grow professionally in the field and become customers’ preferred Travel Partner. Sharayu Holidays is not just another travel agency. We, at Sharayu Holidays work with many schools, colleges and universities. We are educators with the knowledge and understanding of what makes a trip a unique travel experience. It is our goal to make your tour live up to your expectations, while taking the pressure of the logistics off to you. We offer an unrivalled full-spectrum of Customized Holidays and Educational Trips to multiple locations across the world. Whether you are a Professor, Principal or Administrator we can help you to plan a tour as per the requirements of your Esteemed Institute. We have different types of programs suitable as per age level of students. All the tour are professionally designed, high in quality and cost effective enabling you to fulfil your needs and dreams. No matter wherever you go, you will be supported by our team 24/7 throughout the tour. Once on tour, our tour executive will manage all the on-tour logistics allowing yourself and your students to enjoy at the fullest. 
Go ahead and give us a call, then sit back and let the dream begin. Unprecedented Hospitality is Our Motto
            <br />
            <br />
            Our Top Priority
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Safety
            </li>
            <li className="about-activity">
              <ImPointRight /> Spread Knowledge
            </li>
            <li className="about-activity">
              <ImPointRight /> At the End of The Day Smile at your face
            </li>
          </ul>

          <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Sharayu</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
