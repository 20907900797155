import React from "react";
import { Container,Col} from "react-bootstrap";
// import MenuCard from "./MenuCard";
import MenuOne from "./MenuOne";
import MenuTwo from "./MenuTwo";
import MenuThree from "./MenuThree";
import MenuFour from "./MenuFour"; 
import { Carousel } from "react-bootstrap";
import {
  AiFillPhone,

} from "react-icons/ai";
// import Particle from "../Particle";
// import leaf from "../../Assets/Projects/leaf.png";

function Resturant() {
 
  return (
      
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
          Our Upcoming Packages <strong className="purple"> </strong>
        </h1>
        <p style={{ color: "white" }}>
        &#11013;&#65039; Swipe &#10145;&#65039;
        </p>
        <Carousel>
          <Carousel.Item>
            <h1 className="project-heading">
              North <strong className="purple"> </strong>
            </h1>
            <MenuOne />
          </Carousel.Item>
          <Carousel.Item>
            <h1 className="project-heading">
              East <strong className="purple"> </strong>
            </h1>
            <MenuTwo />
          </Carousel.Item>
          <Carousel.Item>
            <h1 className="project-heading">
              South <strong className="purple"> </strong>
            </h1>
            <MenuThree />
          </Carousel.Item>
          <Carousel.Item>
            <h1 className="project-heading">
              West <strong className="purple"> </strong>
            </h1>
            <MenuFour />
          </Carousel.Item>
        </Carousel>
        
        <Col md={12} className="home-about-social">
            <h1>Call Us!</h1>
            
            <ul className="home-about-social-links">
              
              <li className="social-icons">
                <a
                  href="tel://+919769153597"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillPhone />
                </a>
              </li>
              
            </ul>
          </Col>
      </Container>
    </Container>
    
  );
}

export default Resturant;
